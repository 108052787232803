import React from 'react'
import './Header.scss'

import { AppWrap } from '../../wrapper';

import { motion } from 'framer-motion';

import { images } from '../../constants'

const scaleVariants = {
    whileInView: {
        scale: [0,1],
        opacity: [0,1],
        transition: {
            duration:1,
            ease: 'easeInOut'
        }
    }
}

const Header = () => {
    return (
        <div className='app__header-start app__flex'>
            
            <motion.div 
            whileInView={{ x: [150, 0], opacity: [0,1]}}
            transition={{duration: 0.5, staggerChildren: 0.5, ease: 'easeInOut'}}
            // className='app__header-info'
            viewport={{ once: true }}
            >
                {/* <div className='app__header-badge'>
                    <div className='badge-cmp app-flex'> */}
                        <p className="hi-text">Hi!</p>
                        
                    {/* </div>

                    <div className='tag-cmp app__flex'>
                       

                    </div>   
                </div> */}


                
            </motion.div>
            
            <div className='app__header-name'>

                <motion.div 
                    whileInView={{opacity: [0,1], x: [-150,0]}}
                    transition={{delay: 0.5, duration: 0.5}}
                    viewport={{ once: true }}>
                    <p>I'm</p>
                    
                </motion.div>

                <motion.div 
                    whileInView={{opacity: [0,1]}}
                    transition={{delay: 0.5, duration: 1}}
                    viewport={{ once: true }}>
                    <h1 className='app__header-myname'><span>A</span><span>l</span><span>y</span><span>z</span><span>e</span><span>n</span> <span>J</span><span>e</span><span>r</span><span>a</span><span>j</span></h1>
                    
                </motion.div>

                

            </div>

            

            <motion.div
                whileInView={{ opacity: [0,1], y: [200, 0]}}
                transition={{duration: 0.5, delayChildren: 0.5, delay: 0.75}}
                viewport={{ once: true }}
            >

                <p className="p-text dev-text">A <span>Fullstack Developer</span>, <span>Web Designer</span>, and <span>Software Engineer</span>!</p>
                {/* <p className="p-text">Software Developer</p>  */}

                {/* <motion.img
                    whileInView={{ scale: [0,1]}}
                    transition={{ duration: 1, ease: 'easeInOut'}}
                    src={images.circle}
                    alt='profile_circle'
                    className='overlay_circle' /> */}

                </motion.div>

                
            <motion.div
                variant={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className='app__header-circles'
            >

            {/* ADD MAP FUNCTION FOR FLYIGN IMAGES */}
                
            </motion.div>
        </div>
    )
}

export default AppWrap(Header, 'home')

