import React from 'react'

import './App.scss'
import { About, Footer, Header, Skills, Projects } from './container'
import { Navbar } from './components'

function App() {
  return (
    <div className='app' >
      <Navbar />
      <Header />
      <About />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
