import React, { useState } from 'react'
import './Navbar.scss'

import { HiMenuAlt4, HiX } from 'react-icons/hi'

import { motion } from 'framer-motion'

import { images } from '../../constants/index'

import { saveAs } from 'file-saver'

import { AiOutlineCloudDownload } from 'react-icons/ai'



const Navbar = () => {

    const [toggle, setToggle] = useState(false)

    // const saveFile = () => {
    //     fileSaver.saveAs(
    //       process.env.REACT_APP_CLIENT_URL + "/resources/cv.pdf",
    //       "AlyzenJerajResume"
    //     );
    

    return (
        <nav className='app__navbar'>
            <div className='app__navbar-logo'>
                <img src={images.logo} alt='logo' />
            </div>
            <ul className='app__navbar-links'>
                {['home', 'about', 'projects', 'skills', 'contact'].map((item) => (
                    <li className='app__flex p-text' key={`link-${item}`}>
                        <div />
                        <a href={`#${item}`}>{item}</a>
                    </li>
                ))}
                <li className='p-text app__flex '>
                        
                        <div />
                        
                            
                            <a href='./AlyzenJerajResume.pdf' download className='app__navbar-resumebutton'>
                            {/* <AiOutlineCloudDownload style={{fontSize: '125%'}} className='element'/> */}
                           My Resume
                            
                            </a>
                            
                        
                    </li>
            </ul>

            <div className='app__navbar-menu'>
                <HiMenuAlt4 style={{cursor: 'pointer'}} onClick={() => setToggle(true)} />
                {
                    toggle && (
                        <motion.div 
                        whileInView={{ x: [300, 0]}}
                        transition={{ duration: 0.85, ease: 'easeOut'}} >
                            <HiX style={{cursor: 'pointer'}} onClick={() => setToggle(false)} />
                            <ul>
                            {['home', 'about', 'projects', 'skills', 'contact'].map((item) => (
                                <li onClick={() => setToggle(false)} key={item}>
                                    
                                    <a  href={`#${item}`}>{item}</a>
                                </li>
                             ))}
                             <li onClick={() => setToggle(false)}>
                             <a style={{color: "#38b6ff"}} href='./AlyzenJerajResume.pdf' download >
                                 My Resume
                            
                            </a>
                                    
                            </li>
                                
                             </ul>
                        </motion.div>
                    )
                }
            </div>
        </nav>
    )
}

export default Navbar
