import React from 'react'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { SiDevpost } from 'react-icons/si'

const SocialMedia = () => {
    return (
        <div className='app__social'>
        
        <a href='https://www.linkedin.com/in/alyzenjeraj/' rel="noreferrer" target='_blank'>
            <div  style={{cursor: 'pointer'}} >   
                <BsLinkedin />
            </div>
        </a>
            
        
        
        <a href='https://github.com/alyzenjeraj' rel="noreferrer" target='_blank'>
            <div  style={{cursor: 'pointer'}}>
                <BsGithub />
            </div>
        </a>
            
        
        
        <a href='https://devpost.com/alyzenjeraj' rel="noreferrer" target='_blank'>
            <div   style={{cursor: 'pointer'}} >
                <SiDevpost />
            </div>
        </a>
                
        
            
        </div>
    )
}

export default SocialMedia
